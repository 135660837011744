<template>
  <highcharts :options="pldChart" />
</template>

<script>
import { i18n } from '../main';
import { Chart } from 'highcharts-vue';

export default {
  components: {
    highcharts: Chart
  },
  props: {
    series: Array,
    categories: Array,
    title: String
  },
  mounted() {
    setTimeout(() => {
      this.pldChart.series = this.series;
      this.pldChart.xAxis.categories = this.categories;
      this.pldChart.title.text = this.title;
    }, 1000);
  },
  data() {
    return {
      pldChart: {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          labels: {
            rotation: 0
          }
        },
        yAxis: {
          title: {
            text: i18n.tc('free_market.value_pld')
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            groupPadding: 0,
            grouping: false,
            color: '#004F59',
            dataLabels: {
              enabled: true,
              rotation: 0,
              color: '#FFFFFF',
              align: 'center',
              format: '{point.y:.0f}',
              y: 20,
              style: {
                fontWeight: 'bold',
                fontSize: '12px',
                fontFamily: 'Arial, Verdana, sans-serif',
                textOutline: 'none'
              }
            }
          }
        },
        tooltip: {
          pointFormat: '<b>R$ {point.y:.2f}</b><br/>'
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  enabled: false
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      }
    };
  }
};
</script>
