<template>
  <highcharts :options="fwdChart" constructorType="stockChart" />
</template>

<script>
import groupedCategories from 'highcharts-grouped-categories';
import Highcharts from 'highcharts';
import Stock from 'highcharts/modules/stock';

import { i18n } from '../main';
import { Chart } from 'highcharts-vue';

Stock(Highcharts);
groupedCategories(Highcharts);

export default {
  components: {
    highcharts: Chart
  },
  props: {
    series: Array
  },
  mounted() {
    this.fwdChart.series = this.series;
  },
  data() {
    return {
      fwdChart: {
        title: {
          text: i18n.tc('free_market.titleChart')
        },
        colors: ['#009B3A', '#004F59', '#E57200', '#FFCD00', '#00AD5'],
        rangeSelector: {
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1M'
            },
            {
              type: 'month',
              count: 2,
              text: '2M'
            },
            {
              type: 'all',
              text: i18n.tc('free_market.all')
            }
          ],
          buttonTheme: {
            width: 40
          },
          inputEnabled: false
        },
        yAxis: {
          title: {
            text: i18n.tc('free_market.value')
          },
          labels: {
            align: 'left'
          }
        },
        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        legend: {
          enabled: true,
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'middle'
        },
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  enabled: false
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      }
    };
  }
};
</script>
