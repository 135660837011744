import axios from 'axios';
import dayjs from 'dayjs';
import Vue from 'vue';
import Config from '@/config/config';
import { getMesUltimaSeriePLD } from '../../utils/getMesUltimaSeriePLD';

import {
  LIST_CURVAS,
  LIST_PLD,
  SERIES_CURVAS,
  FWD_AHEAD
} from '../../utils/endPoints';

import { getAuth } from '../auth/auth.helpers';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const getFwdSeCo = async () => {
  // eslint-disable-next-line no-unused-vars
  const requestBody = {
    curvas: [
      {
        nomeCurva: 'FWD_SE_CO',
        mesProduto: getMesUltimaSeriePLD(3)
      },
      {
        nomeCurva: 'FWD_SE_CO',
        mesProduto: getMesUltimaSeriePLD(2)
      },
      {
        nomeCurva: 'FWD_SE_CO',
        mesProduto: getMesUltimaSeriePLD(1)
      }
    ],
    dataRefIni: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
    dataRefFim: dayjs().format('YYYY-MM-DD'),
    valorPremio: 0.0
  };

  return new Promise((resolve) => {
    setTimeout(async () => {
      var data = JSON.stringify(requestBody);

      const { token } = getAuth();

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}${SERIES_CURVAS}${LIST_CURVAS}`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          Vue.$toast.error(error, {
            position: 'top-right',
            duration: 5000
          });
        });
    }, 1000);
  });
};

export const getFwdChartSeries = async () => {
  const data = await getFwdSeCo();

  return data.map((serie) => {
    const name = dayjs(serie.curva.mesProduto).format('MMM_YYYY').toUpperCase();
    const data = [];
    const totalOfSeries = serie.dataRef.length;

    for (let i = 0; i < totalOfSeries; i++) {
      const x = dayjs(serie.dataRef[i]).valueOf();
      const y = serie.valor[i];

      data.push([x, y]);
    }

    return {
      name: `FWD_${name}`,
      data
    };
  });
};

export const getPdl = async (submercado) => {
  // eslint-disable-next-line no-unused-vars
  const requestBody = {
    series: [`PLD_${submercado}`],
    mesProdutoIni: getMesUltimaSeriePLD(null, 11),
    mesProdutoFim: getMesUltimaSeriePLD(1),
    incluiProjecao: true
  };

  return new Promise((resolve) => {
    setTimeout(async () => {
      var data = JSON.stringify(requestBody);

      const { token } = getAuth();

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}${SERIES_CURVAS}${LIST_PLD}`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          Vue.$toast.error(error, {
            position: 'top-right',
            duration: 5000
          });
        });
    }, 300);
  });
};

export const getPdlChartSeries = async (submercado) => {
  const data = (await getPdl(submercado))[0];

  const totalOfSeries = data.mesRef.length;
  const totalOfProjections = data.mesProj.length;

  const series = [];

  for (let i = 0; i < totalOfSeries; i++) {
    const x = dayjs(data.mesRef[i]).valueOf();
    const y = data.valorSerie[i];

    series.push([x, y]);
  }

  const projections = [];

  for (let i = 0; i < totalOfProjections; i++) {
    const x = dayjs(data.mesProj[i]).valueOf();
    const y = data.valorProj[i];

    projections.push([x, y]);
  }
  return {
    series,
    projections
  };
};

export const getAgio = async (submercado) => {
  const date1 = dayjs().startOf('month').format('YYYY-MM-DD');

  // eslint-disable-next-line no-unused-vars
  const date = new Date();
  const datePreviusTimestamp = date.setDate(date.getDate());
  const datePrevius = dayjs(datePreviusTimestamp).format('YYYY-MM-DD');

  const requestBody = {
    curvas: [
      {
        nomeCurva: `AGIO_${submercado}_CO`,
        mesProduto: date1
      },
      {
        nomeCurva: `AGIO_${submercado}_I5`,
        mesProduto: date1
      }
    ],

    dataRefIni: datePrevius,
    dataRefFim: datePrevius
  };

  return new Promise((resolve) => {
    setTimeout(async () => {
      var axios = require('axios');
      var data = JSON.stringify(requestBody);
      const { token } = getAuth();

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}${SERIES_CURVAS}${LIST_CURVAS}`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          Vue.$toast.error('Ocorreu algum erro: ' + error, {
            position: 'top-right',
            duration: 5000
          });
        });
    }, 300);
  });
};

const AGIO_MAP = {
  CO: 'Convencional',
  I5: 'Incentivada 50%'
};

const currency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  signDisplay: 'exceptZero'
});

export const getAgioBoard = async (submercado) => {
  const data = await getAgio(submercado);

  const month = dayjs(data[0].curva.mesProduto).format('MMM/YYYY');

  const values = data.map((item) => {
    const nome = item.curva.nomeCurva;
    const last = nome.slice(nome.length - 2);
    const key = AGIO_MAP[last];
    const value = currency.format(item.valor[0]);

    return {
      key,
      value: `${value}/MWh`
    };
  });

  return {
    month,
    values
  };
};

export const getFWDYearAhead = async (submercado) => {
  const requestBody = {
    curvas: [`FWD_${submercado}_CO`, `FWD_${submercado}_I5`],
    anoIni: dayjs().add(1, 'year').format('YYYY-MM-DD'),
    anoFim: dayjs().add(3, 'year').format('YYYY-MM-DD'),
    valorPremio: 2.0
  };

  return new Promise((resolve) => {
    setTimeout(async () => {
      var data = JSON.stringify(requestBody);

      const { token } = getAuth();

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}${SERIES_CURVAS}${FWD_AHEAD}`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          Vue.$toast.error(error, {
            position: 'top-right',
            duration: 5000
          });
        });
    }, 300);
  });
};
