<template>
  <highcharts :options="chartFwdAhead" />
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  components: {
    highcharts: Chart
  },
  props: {
    options: Array,
    datas: Array,
    valoresCO: Array,
    nomeCurvaCO: String,
    valoresI5: Array,
    nomeCurvaI5: String,
    title: String
  },
  created() {
    this.chartFwdAhead.title.text = this.title;
    this.chartFwdAhead.xAxis.categories = this.datas;
    this.chartFwdAhead.series[0].data = this.valoresCO;
    this.chartFwdAhead.series[0].name = this.nomeCurvaCO;
    this.chartFwdAhead.series[1].data = this.valoresI5;
    this.chartFwdAhead.series[1].name = this.nomeCurvaI5;
  },
  data() {
    return {
      chartFwdAhead: {
        chart: {
          type: 'column',
          height: '200px'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Valor (R$)'
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value;
            }
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: 'bold',
              color: 'gray'
            },
            format: '{total:.0f}'
          }
        },

        tooltip: {
          headerFormat: '<b>Ano</b>: {point.x}<br/>',
          pointFormat: '<b>{series.name}: R$ {point.y:.0f}<br/>'
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              rotation: 0,
              color: '#FFFFFF',
              align: 'center',
              format: '{point.y:.0f}',
              y: 20,
              style: {
                fontWeight: 'bold',
                fontSize: '12px',
                fontFamily: 'Arial, Verdana, sans-serif',
                textOutline: 'none'
              }
            }
          }
        },
        series: [
          {
            color: '#E57200',
            data: [],
            tooltip: {
              valueDecimals: 1,
              valueSuffix: ' R$',
              valuePrefix: '',
              split: true
            }
          },
          {
            color: '#009B3A',
            data: [],
            tooltip: {
              valueDecimals: 1,
              valueSuffix: ' R$',
              valuePrefix: '',
              split: true
            }
          }
        ],
        legend: {
          enabled: true
        },
        credits: {
          enabled: false
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 100
              },
              chartOptions: {
                legend: {
                  enabled: false
                }
              }
            }
          ]
        }
      }
    };
  }
};
</script>
