import dayjs from 'dayjs';

export function getMesUltimaSeriePLD(addMonth, subtractMonth) {
  if (!subtractMonth) {
    return dayjs().add(addMonth, 'month').startOf('month').format('YYYY-MM-DD');
  }

  return dayjs()
    .subtract(subtractMonth, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');
}
